<template>
  <div v-loading="loading" v-if="form" style="width: 600px;">
    <div v-if="editable" style="margin-bottom: 10px;">
      <el-popover trigger="click" v-if="showProChoose">
        <el-cascader-panel ref="propertyPanel" :options="proOptions" :props="{label: 'name', value: 'name'}" @change="handlePropertySelect"></el-cascader-panel>
        <el-button slot="reference" size="mini" type="info">选择属性</el-button>
      </el-popover>
      <el-button @click.stop="handlePropertyAdd" size="mini" type="info">添加属性</el-button>
    </div>
    <el-table ref="list" :data="form.properties" size="mini" empty-text="该商品还没有设置属性" v-if="editable">
      <el-table-column label="属性名称" min-width="50">
        <template slot-scope="scope">
          <el-input v-model.trim="scope.row.name" :maxlength="50" placeholder="请输入属性的名称" />
        </template>
      </el-table-column>
      <el-table-column label="属性值" min-width="50">
        <template slot-scope="scope">
          <el-input v-model.trim="scope.row.value" :maxlength="50" placeholder="请输入属性的值" />
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="50">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" @click.stop="handlePropertyRemove(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-table ref="list" :data="form.properties" size="mini" empty-text="该商品没有设置属性" v-else>
      <el-table-column prop="name" label="属性名" min-width="50" />
      <el-table-column prop="value" label="属性值" min-width="50" />
    </el-table>

    <div style="margin-top: 20px;" v-if="editable">
      <el-button type="primary" icon="el-icon-check" :loading="saving" @click="handleSubmit" v-permission="['GOODS_ALL', 'GOODS_EDIT']">保存商品属性</el-button>
    </div>
  </div>
</template>
<script>
import {
  getPropertiesByCategory,
  getProperty,
  saveProperty,
} from "@/api/goods";
export default {
  props: {
    form: Object,
    editable: Boolean,
  },
  data() {
    return {
      loading: false,
      proOptions: [],
      showProChoose: false,
      saving: false,
    };
  },
  mounted() {
    this.loadPropertyOptions();
    this.loadProperty();
  },
  methods: {
    doLayout() {
      this.$refs.list && this.$refs.list.doLayout();
    },
    loadProperty() {
      if (this.form && this.form.id) {
        this.loading = true;
        getProperty(this.form.id)
          .then((res) => {
            this.form.properties = res;
          })
          .then((_) => {
            this.loading = false;
          });
      }
    },
    handlePropertyRemove(row) {
      let inx = (this.form.properties || []).indexOf(row);
      if (inx >= 0) this.form.properties.splice(inx, 1);
    },
    handlePropertyAdd(target) {
      if (!this.form.properties) {
        this.form.properties = [];
      }
      this.form.properties.push({ name: "", value: "" });
    },
    handlePropertySelect(arr) {
      if (!this.form.properties) {
        this.form.properties = [];
      }
      let exists = this.form.properties.find((item) => {
          return item.name === arr[0];
        }),
        o = {
          name: arr[0],
          value: arr[1],
        };
      if (exists) Object.assign(exists, o);
      else this.form.properties.push(o);
    },
    loadPropertyOptions() {
      this.showProChoose = false;
      if (this.form && this.form.categoryId) {
        getPropertiesByCategory(this.form.categoryId).then((res) => {
          if (res && res.content && res.content.length) {
            res.content.forEach((item) => {
              item.children = (item.dataEnum || "").split("|").map((v) => {
                return {
                  name: v,
                };
              });
            });
            this.proOptions = res.content;
            this.showProChoose = true;
          }
        });
      }
    },
    handleSubmit() {
      if (this.form && this.form.id) {
        this.saving = true;
        saveProperty(
          this.form.id,
          (this.form.properties || []).filter((p) => {
            return p.name && p.value;
          })
        )
          .then((res) => {
            this.$notify({
              title: "保存商品属性成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.saving = false;
          });
      }
    },
  },
  watch: {
    "form.categoryId": "loadPropertyOptions",
  },
};
</script>
<template>
  <el-form ref="form" :model="form" :rules="rules" label-position="right" :hide-required-asterisk="!editable" label-suffix=":" label-width="88px" v-if="form">
    <div style="width: 840px;">
      <div :class="{layout_disabled: !editable}">
        <!-- <el-form-item label="ERP编码" prop="erpGoodsCode" style="width: 360px;">
            <el-input v-model="form.erpGoodsCode" :maxlength="40" @keyup.enter.native="queryByErpCode">
              <el-button slot="append" icon="el-icon-search" @click="queryByErpCode" :loading="loading" v-if="editable"></el-button>
            </el-input>
          </el-form-item>
          <div class="h">
            <el-form-item label="商品编码" prop="code" style="width: 360px;">
              <el-input v-model="form.code" :maxlength="40" />
            </el-form-item>
            <el-form-item label="条形码" prop="barcode" style="width: 360px;">
              <el-input v-model="form.barcode" :maxlength="40" />
            </el-form-item>
        </div>-->
        <el-form-item label="商品名称" prop="name" style="width: 840px;">
          <el-input v-model.trim="form.name" :maxlength="100" />
        </el-form-item>
        <div class="h sb">
          <el-form-item label="品牌" prop="brandId" style="width: 400px;">
            <quick-select url="api/brand" v-model="form.brandId" filterable placeholder="请选择品牌" style="width: 100%;" @change="form.seriesId = null" />
          </el-form-item>
          <el-form-item label="所属分类" prop="categoryId" style="width: 400px;">
            <tree-picker v-model="form.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 100%;" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="系列" prop="seriesId" style="width: 400px;">
            <tree-picker v-if="form.brandId" v-model="form.seriesId" :params="{brandId:form.brandId,enabled: true}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 100%;" />
            <span class="fc-g" v-else>请先选择品牌</span>
          </el-form-item>
          <el-form-item label="计量单位" prop="unitId" style="width: 400px;">
            <quick-select url="api/unit" v-model="form.unitId" filterable placeholder="请选择计量单位" style="width: 100%;" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="销售价格" prop="price" style="width: 400px;">
            <price-input v-model="form.price" style="width: 100%;" />
          </el-form-item>
          <el-form-item label="交货周期" prop="deliveryCycle" style="width: 400px;">
            <div class="h c">
              <el-input-number type="number" v-model="form.deliveryCycle" :min="0" :max="365" :step="1" :precision="0" controls-position="right" @change="$checkNaN(form, 'deliveryCycle', 0)" class="flex" />
              <span>&nbsp;天</span>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="h s">
        <el-form-item label="商品图片" prop="coverImg">
          <file-uploader v-model="form.coverImg" :entity-id="form.fileId" folder="goods/cover_img" :thumbnail-size="80" :readonly="!editable"></file-uploader>
        </el-form-item>
        <el-form-item label="更多图片" class="flex">
          <file-uploader :entity-id="form.fileId" folder="goods/details" :thumbnail-size="80" multiple :readonly="!editable"></file-uploader>
        </el-form-item>
      </div>

      <el-form-item label="备注说明">
        <el-input v-model="form.remindWords" :maxlength="50" />
      </el-form-item>

      <el-form-item label="详情描述" prop="commodityDes">
        <!-- <el-input v-model="form.info" type="textarea" resize="none" :rows="4" :maxlength="1000" /> -->
        <html-editor v-model="form.info" :height="306" v-if="editable" />
        <div class="padding-10 bc-l" style="line-height: 1.3;" v-html="form.info || defaultInfo" v-else></div>
      </el-form-item>
    </div>
    <el-form-item v-if="editable">
      <el-button type="primary" icon="el-icon-check" @click.stop="handleSubmit" v-permission="['GOODS_ALL', 'GOODS_EDIT']">保存基本信息</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { add, edit, getByErpCode } from "@/api/goods";
import HtmlEditor from "@/views/components/common/htmlEditor";

export default {
  components: { HtmlEditor },
  props: {
    form: Object,
    editable: Boolean,
  },
  data() {
    return {
      categories: null,
      loading: false,
      defaultInfo: "<span class='fc-g'>该商品没有详情描述</span>",
      rules: {
        // spuCode: [{ required: true, message: "请输入SPU编码" }],
        code: [{ required: true, message: "请输入商品编码" }],
        erpGoodsCode: [{ required: true, message: "请输入ERP编码" }],
        barcode: [{ required: true, message: "请输入条形码" }],
        name: [{ required: true, message: "请输入商品名称" }],
        brandId: [{ required: true, message: "请选择品牌" }],
        seriesId: [{ required: true, message: "请选择系列" }],
        unitId: [{ required: true, message: "请选择计量单位" }],
        categoryId: [{ required: true, message: "请选择所属分类" }],
      },
    };
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        if (this.$refs.form) {
          this.$refs.form.validate((_valid) => {
            if (_valid) {
              resolve();
            } else {
              reject("basic");
            }
          });
        } else {
          reject("basic");
        }
      });
    },
    queryByErpCode() {
      if (!this.form.erpGoodsCode) return;
      this.loading = true;
      getByErpCode(this.form.erpGoodsCode)
        .then((res) => {
          this.form.code = res.code;
          this.form.name = res.name;
        })
        .catch((err) => {
          this.form.code = "";
          this.form.name = "";
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    handleSubmit() {
      if (this.form) {
        this.$refs.form &&
          this.$refs.form.validate().then((_) => {
            (this.form.id ? edit : add)(this.form).then((res) => {
              this.$notify({
                title: "保存商品基本信息成功",
                type: "success",
                duration: 2500,
              });
              if (res) this.$emit("submit", res);
            });
          });
      }
    },
  },
};
</script>
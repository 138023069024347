<template>
  <div class="flex">
    <div class="flex h c" style="margin-bottom: 12px;">
      <el-button type="primary" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
      <div class="padding-0-20" v-if="form">
        <div class="fc-g" style="font-size: 12px;">当前商品</div>
        <div style="margin-top: 2px;">
          <b>{{form.name || "-"}}</b>
        </div>
      </div>
    </div>
    <el-tabs v-model="currentTab" v-if="form" @tab-click="handleTabClick">
      <el-tab-pane name="base" label="基本信息">
        <e-basic ref="base" :form="form" :editable="editable" @submit="handleBaseSubmit" />
      </el-tab-pane>
      <el-tab-pane name="property" label="商品属性" v-if="form.id">
        <e-property ref="property" :form="form" :editable="editable" v-if="inited.property" />
      </el-tab-pane>
      <el-tab-pane name="sku" label="SKU信息" v-if="form.id">
        <e-sku ref="sku" :form="form" :editable="editable" v-if="inited.sku" />
      </el-tab-pane>
    </el-tabs>
    <div v-else v-loading="loading" style="height: 100px;"></div>
  </div>
</template>

<script>
import eBasic from "./editors/basic";
import eProperty from "./editors/property";
import eSku from "./editors/sku";
import { get } from "@/api/goods";
import { mapGetters } from "vuex";
import checkPermission from "@/utils/permission"

export default {
  components: { eBasic, eProperty, eSku },
  computed: {
    ...mapGetters(["user"]),
    editable() {
      return this.form && this.form.entId === this.user.entId && this.checkPermission(['GOODS_ALL', 'GOODS_ADD','GOODS_EDIT']);
    }
  },
  props: {
    id: { type: String }
  },
  data() {
    return {
      form: null,
      currentTab: "base",
      loading: false,
      inited: {
        property: false,
        sku: false
      }
    };
  },
  methods: {
    checkPermission,
    handleBack() {
      this.$emit("cancel");
    },
    handleTabClick(tab) {
      if (tab && tab.name) {
        this.inited[tab.name] = true;
        this.$nextTick(_ => {
          let el = this.$refs[tab.name];
          el && el.doLayout && el.doLayout();
        });
      }
    },
    handleBaseSubmit(data) {
      if (this.form) {
        Object.assign(this.form, data);
      }
    },
    resetForm() {
      this.currentTab = "base";
      this.inited.property = false;
      this.inited.sku = false;
      if (this.id) {
        this.loading = true;
        get(this.id)
          .then(res => {
            if (isNaN(res.deliveryCycle)) res.deliveryCycle = 7;
            this.form = res;
          })
          .finally(_ => {
            this.loading = false;
          });
      } else {
        this.form = {
          id: null,
          name: "",
          coverImg: null,
          info: "",
          brandId: null,
          categoryId: null,
          seriesId: null,
          unitId: null,
          remindWords: "",
          entId: this.user.entId,
          fileId: this.$uuid()
        };
      }
    }
  },
  mounted() {
    this.resetForm();
  }
};
</script>